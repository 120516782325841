import { Alert } from "@mui/material";
import { FC } from "react";

interface IProps {
  error: any;
}

const styles = {
  alert: {
    width: { xs: "200px", sm: "300px" },
    textAlign: "center",
    "& .MuiAlert-message::first-letter": {
      textTransform: "uppercase",
    },
  },
};

const Error: FC<IProps> = ({ error }) => {
  if (Object.keys(error).length === 0) {
    return null;
  }
  return (
    <Alert severity="error" sx={styles.alert}>
      {error}
    </Alert>
  );
};

export default Error;
