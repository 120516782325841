import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CreateIcon from "@mui/icons-material/Create";
import ModalCorrections from "../modal/ModalCorrections";
import CustomError from "../helpers/CustomError";
import Loader from "../helpers/Loader";
import { useAppDispatch } from "../../store/hooks/hooks";
import {
  IEditOneFromModal,
  setEditOneFromTable,
  setModalState,
} from "../../store/slices/modal.slice";
import { useGetAllQuery } from "../../store/api/logged.api";

const styles = {
  fileName: {
    width: "200px",
    wordBreak: "break-all",
  },
};

const TableCampanies = () => {
  const dispatch = useAppDispatch();
  const handleOpenModal = (data: IEditOneFromModal) => {
    dispatch(setModalState(true));
    dispatch(setEditOneFromTable(data));
  };
  const { data, isLoading, error } = useGetAllQuery();
  if (isLoading) return <Loader />;
  if (error) return <CustomError />;
  return (
    <Box>
      <Paper sx={{ p: 5 }} elevation={24}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">Кампания</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Количество линий</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Остаток номеров</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Список</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Режим работы</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Статус</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Действия</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.campaigns.map((el) => {
                return (
                  <TableRow key={el.id} hover>
                    <TableCell>
                      <Typography fontSize="14px" color="primary">
                        {el.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="14px" variant="body1">
                        {" "}
                        {el.numberOfAvailableLines}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="14px" variant="body1">
                        {el.countPhones}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Box sx={styles.fileName}>
                        {Array.isArray(el.file_name) &&
                          el.file_name.length &&
                          el.file_name.map((file) => (
                            <Typography
                              fontSize="14px"
                              key={file}
                              variant="body1"
                            >
                              {file}
                            </Typography>
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="14px" variant="body1">
                        {`${String(el.timestart).slice(0, -2)}:${String(
                          el.timestart
                        ).slice(-2)}
                      - ${String(el.timeend).slice(0, -2)}:${String(
                          el.timeend
                        ).slice(-2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="14px" variant="body1">
                        {" "}
                        {JSON.parse(el.start) ? "В работе" : "Остановлено"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Запустить" placement="top">
                        <IconButton>
                          <PlayCircleOutlineIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Редактировать" placement="top">
                        <IconButton
                          onClick={() =>
                            handleOpenModal({
                              id: el.id,
                              campaign_id: el.id,
                              name: el.name,
                            })
                          }
                        >
                          <CreateIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <ModalCorrections />
    </Box>
  );
};

export default TableCampanies;
