import { Button, Stack, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useAppDispatch } from "../../store/hooks/hooks";
import { setAuth } from "../../store/slices/auth.slice";

const Navigation = () => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(setAuth(false));
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userSurname");
    sessionStorage.removeItem("AccessToken");
  };
  return (
    <Stack
      height={50}
      sx={{ backgroundColor: blue[100] }}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      borderBottom={`1px solid ${blue[400]}`}
      borderRadius="8px 8px 0 0"
    >
      <Typography variant="body1" mr={4}>
        {sessionStorage.getItem("userName")}{" "}
        {sessionStorage.getItem("userSurname")}
      </Typography>
      <Button onClick={handleClick} sx={{ mr: 2 }}>
        Выйти
      </Button>
    </Stack>
  );
};

export default Navigation;
