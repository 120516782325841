import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLoginMutation } from "../../store/api/auth.api";
import { yupResolver } from "@hookform/resolvers/yup";
import { authValidationSchema } from "../../yup/auth.validation";

import Error from "../helpers/ErrorAuth";
import { useAppDispatch } from "../../store/hooks/hooks";
import { setAuth } from "../../store/slices/auth.slice";
import { useState } from "react";

interface IForm {
  login: string;
  password: string;
}

const Auth = () => {
  const dispatch = useAppDispatch();
  const [trigger] = useLoginMutation();
  const [errors, setErrors] = useState("");
  const { register, handleSubmit } = useForm<IForm>({
    resolver: yupResolver(authValidationSchema),
  });
  const handleFormSubmit: SubmitHandler<IForm> = (data) => {
    trigger(data)
      .unwrap()
      .then((response) => {
        sessionStorage.setItem(
          "AccessToken",
          response.message.Token.AccessToken
        );
        sessionStorage.setItem("userName", response.message.Name);
        sessionStorage.setItem("userSurname", response.message.Surname);
        dispatch(setAuth(true));
      })
      .catch((err) => {
        console.log(err);
        if (err.status === "FETCH_ERROR") setErrors("Ошибка подключения");
        else {
          setErrors(err.data.message);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack
        alignItems="center"
        sx={{ width: "100vw", height: "100vh" }}
        mt={30}
      >
        <Paper
          elevation={24}
          sx={{ py: 5, px: 10, width: { xs: "230px", sm: "330px" } }}
        >
          <Stack spacing={3}>
            <Typography variant="h6">Авторизация</Typography>
            <TextField
              label="Логин"
              variant="standard"
              {...register("login")}
            />
            <TextField
              label="Пароль"
              variant="standard"
              type="password"
              {...register("password")}
            />
            <Error error={errors} />
            <Button type="submit" sx={{ py: 1 }} variant="contained">
              Войти
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </form>
  );
};

export default Auth;
