import { Alert, Stack } from "@mui/material";

const CustomError = () => {
  return (
    <Stack direction="row" justifyContent="center" mt={10}>
      <Alert severity="error"> Ошибка подключения</Alert>
    </Stack>
  );
};

export default CustomError;
