import Navigation from "../navigation/Navigation";
import TableCampanies from "../table/TableCampanies";

const Table = () => {
  return (
    <div>
      <Navigation />
      <TableCampanies />
    </div>
  );
};

export default Table;
