import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./components/pages/Auth";
import Table from "./components/pages/Table";
import { useAppSelector } from "./store/hooks/hooks";

function App() {
  const auth = useAppSelector((state) => state.auth.auth);

  return (
    <BrowserRouter>
      {auth ? (
        <Routes>
          <Route path="/" element={<Table />} />
          <Route path="*" element={<Table />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="*" element={<Auth />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
