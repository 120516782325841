import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../api/auth.api";
import { loggedApi } from "../api/logged.api";
import authSlice from "../slices/auth.slice";

import modalSlice from "../slices/modal.slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    modal: modalSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [loggedApi.reducerPath]: loggedApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, loggedApi.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
