import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { ICampaigns, IEditOne, IResponseUploadFile } from "../constants/types";

export const loggedApi = createApi({
  reducerPath: "loggedApi",
  tagTypes: ["all"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAll: builder.query<ICampaigns, void>({
      query: () => "/campaigns/all",
      providesTags: ["all"],
    }),
    editOne: builder.mutation<any, IEditOne>({
      query: (data) => ({
        url: `/campaigns/one/:${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["all"],
    }),
    uploadFile: builder.mutation<IResponseUploadFile, any>({
      query: (data: any) => ({
        url: "/campaigns/uploadFile",
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["all"],
    }),
  }),
});

export const {
  useGetAllQuery,
  useLazyGetAllQuery,
  useEditOneMutation,
  useUploadFileMutation,
} = loggedApi;
