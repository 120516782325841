import { createSlice } from "@reduxjs/toolkit";

export interface IEditOneFromModal {
  id: number;
  name: string;
  campaign_id: number;
}

interface IInitialState {
  isOpenedModal: boolean;
  editCampany: IEditOneFromModal;
}

const initialState: IInitialState = {
  isOpenedModal: false,
  editCampany: {
    id: 0,
    name: "",
    campaign_id: 0,
  },
};

const modalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    setModalState: (state, action) => {
      state.isOpenedModal = action.payload;
    },
    setEditOneFromTable: (state, action) => {
      state.editCampany.name = action.payload.name;
      state.editCampany.campaign_id = action.payload.id;
      state.editCampany.id = action.payload.id;
    },
  },
});

export default modalSlice;
export const { setModalState, setEditOneFromTable } = modalSlice.actions;
