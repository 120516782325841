import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { ChangeEvent, useState } from "react";
import InputMask from "react-input-mask";
import {
  useEditOneMutation,
  useUploadFileMutation,
} from "../../store/api/logged.api";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { setModalState } from "../../store/slices/modal.slice";

const ModalCorrections = () => {
  const dispatch = useAppDispatch();
  const isOpenedModal = useAppSelector((state) => state.modal.isOpenedModal);
  const editOneFromTable = useAppSelector((state) => state.modal.editCampany);

  const handleCloseModal = () => {
    clearForm();
    dispatch(setModalState(false));
  };

  const [lines, setLines] = useState("");
  const handleLines = (e: ChangeEvent<HTMLInputElement>) => {
    setLines(e.target.value);
  };

  const [startWork, setStartWork] = useState("");
  const startWorkWithTwo = startWork === "2";
  const startWorkMask = [
    /[0-2]/,
    startWorkWithTwo ? /[0-3]/ : /[0-9]/,
    ":",
    /[0-5]/,
    /[0-9]/,
  ];
  const handleStartWork = (e: ChangeEvent<HTMLInputElement>) => {
    setStartWork(e.target.value);
  };
  const [endWork, setEndWork] = useState("");
  const endWorkWithTwo = startWork === "2";
  const endWorkMask = [
    /[0-2]/,
    endWorkWithTwo ? /[0-3]/ : /[0-9]/,
    ":",
    /[0-5]/,
    /[0-9]/,
  ];
  const handleEndWork = (e: ChangeEvent<HTMLInputElement>) => {
    setEndWork(e.target.value);
  };

  const [filter, setFilter] = useState("down");
  const handleFilter = (e: SelectChangeEvent) => {
    setFilter(e.target.value);
  };

  const [file, setFile] = useState<File>();
  const [route, setRoute] = useState("");
  const handleNumbers = (e: ChangeEvent<HTMLInputElement>) => {
    setRoute(e.target.value);
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const [trigger] = useEditOneMutation();
  const [triggerFile] = useUploadFileMutation();

  const sendData = () => {
    const formData = new FormData();
    formData.append("file", file!);
    formData.append("campaign_id", String(editOneFromTable.campaign_id));
    const timestart = startWork.split(":").join("");
    const timeend = endWork.split(":").join("");

    if (lines === "" || filter === "" || startWork === "" || endWork === "") {
      if (route === "") {
        alert("Заполните поля ввода");
        return;
      }

      triggerFile(formData)
        .unwrap()
        .then((response) => {
          handleCloseModal();
          console.log(response);
          alert(
            `${response.message}. Дублей: ${response.dublicatePhones}. Невалидных номеров: ${response.invalidPhones}`
          );
        })
        .catch((error) => console.log(error));
      return;
    }

    if (timestart > timeend) {
      alert("Некорректные значения полей ввода раздела 'Время работы'");
      return;
    }
    if (String(timestart).length < 4 || String(timeend).length < 4) {
      alert(
        "Каждое из полей времени работы должно иметь 4 символа, например: 02:00"
      );
      return;
    }
    trigger({
      ...editOneFromTable,
      numberOfAvailableLines: Number(lines),
      selectionOfNumbers: filter,
      timestart,
      timeend,
      start: String(startWork !== endWork),
    })
      .unwrap()
      .then((response) => {
        console.log(response);
        alert(response.message);
      })
      .catch((error) => console.log(error));

    if (route !== "") {
      triggerFile(formData)
        .unwrap()
        .then((response) => {
          handleCloseModal();
          alert(
            `${response.message}. Дублей: ${response.dublicatePhones}. Невалидных номеров: ${response.invalidPhones}`
          );
        })
        .catch((error) => console.log(error));
    } else {
      handleCloseModal();
    }
  };

  const clearForm = () => {
    setLines("");
    setStartWork("");
    setEndWork("");
    setRoute("");
  };

  return (
    <Modal
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={isOpenedModal}
      onClose={handleCloseModal}
    >
      <Paper elevation={24} sx={{ pb: 5, width: "600px", borderRadius: 2 }}>
        <Stack
          justifyContent="center"
          height={50}
          bgcolor={blue[100]}
          mb={3}
          borderRadius="8px 8px 0 0"
        >
          <Typography variant="overline" ml={2}>
            Редактирование кампании
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <Stack width="80%" mb={4}>
            <Typography variant="h5" gutterBottom>
              Количество доступных линий
            </Typography>
            <Typography mb={3}>
              Указывается количество доступных линий для совершения звонков.
              Количество совершаемых звонков не может быть больше чем
              предоставляет провайдер связи
            </Typography>
            <InputMask
              mask="999"
              value={lines}
              onChange={handleLines}
              maskPlaceholder={""}
            >
              <Input type="text" placeholder="Введите количество линий" />
            </InputMask>
          </Stack>
          <Stack width="80%" mb={4}>
            <Typography variant="h5" gutterBottom>
              Время работы
            </Typography>
            <Typography gutterBottom>
              Указывается время начала и окончания работы кампании
            </Typography>
            <Typography gutterBottom>
              Например, начало/конец в 02:00 указывается как 02:00
            </Typography>
            <Stack direction="row" justifyContent="space-between" mt={3}>
              <InputMask
                mask={startWorkMask}
                value={startWork}
                onChange={handleStartWork}
                maskPlaceholder={""}
                placeholder="Время начала работы"
              >
                <Input sx={{ width: "45%" }} />
              </InputMask>
              <InputMask
                mask={endWorkMask}
                placeholder="Время окончания работы"
                value={endWork}
                onChange={handleEndWork}
                maskPlaceholder={""}
              >
                <Input sx={{ width: "45%" }} />
              </InputMask>
            </Stack>
          </Stack>
          <Stack width="80%" mb={4}>
            <Typography gutterBottom variant="h5">
              Выборка номеров
            </Typography>
            <Typography mb={3}>
              Выбирать номера с начала таблицы или с конца
            </Typography>
            <FormControl>
              <InputLabel id="select">
                Выбирать номера с начала таблицы или с конца
              </InputLabel>
              <Select
                labelId="select"
                label="Выбирать номера с начала таблицы или с конца"
                value={filter}
                onChange={handleFilter}
              >
                <MenuItem value="up">Сперва новые</MenuItem>
                <MenuItem value="down">Сперва старые</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack width="80%" mb={4}>
            <Typography gutterBottom variant="h5">
              Загрузка номеров в кампанию
            </Typography>
            <TextField
              type="file"
              value={route}
              onChange={handleNumbers}
              inputProps={{ accept: ".csv" }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            width="80%"
            spacing={3}
          >
            <Button variant="contained" color="success" onClick={sendData}>
              Cохранить
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Отменить
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default ModalCorrections;
