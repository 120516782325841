import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { API_URL } from "./constants/common";
import { setAuth } from "./slices/auth.slice";

const prepareHeaders = (headers: any) => {
  const accessToken = sessionStorage.getItem("AccessToken");
  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }
  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: prepareHeaders,
});

export const baseQueryWithReauth = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    sessionStorage.removeItem("AccessToken");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userSurName");
    api.dispatch(setAuth(false));
  }
  return result;
};
